import { Fetch } from '../fetch/FetchBase.js'
// const authToken = () => JSON.parse(localStorage.getItem('vuex')).auth.user.token
const API_URL = process.env.VUE_APP_AUTH_API_URL + '/api/v1'
const API_TOKEN = process.env.VUE_APP_AUTH_API_TOKEN

const headers = {
  'api-token': API_TOKEN
}
const AuthApiClient = new Fetch({ baseURL: API_URL, headers })

export const AuthApiEndpoints = {
  login(data = { email: '', password: '' }) {
    return AuthApiClient.post({
      url: '/auth/login/local',
      body: data
    })
  },
  loginWithToken(token) {
    return AuthApiClient.get({
      url: '/users/profile',
      headers: {
        'auth-token': token
      }
    })
  }
}

// login(data = { email: '', password: '' }) {
//   return authAxiosInstaceV1({
//     method: 'post',
//     data: data,
//     url: `/auth/login/local`,
//     headers: {
//       'api-token': AUTH_API_TOKEN
//     }
//   })
// }

// logout() {
//   return authAxiosInstaceV1({
//     method: 'POST',
//     url: `/users/logout`,
//     headers: {
//       'auth-token': authToken(),
//       'api-token': AUTH_API_TOKEN
//     }
//   })
// },
