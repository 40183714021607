/* eslint-disable prefer-const */
<template>
  <v-container fluid>
    <div class="grey lighten-2" :class="$root.addExtraMargin ? 'ml-14' : null">
      <v-simple-table fixed-header class="my-table" dense height="40vh">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" style="border-right: 1px solid #eeeeee">
                Status
              </th>
              <th class="text-left" style="border-right: 1px solid #eeeeee">
                Campaign Name
              </th>
              <th class="text-left" style="border-right: 1px solid #eeeeee">
                Keywords
              </th>
              <th class="text-left" style="border-right: 1px solid #eeeeee">
                Location
              </th>
              <th
                class="text-left"
                style="width: 15%; border-right: 1px solid #eeeeee"
              >
                Kit Name
              </th>
              <th
                class="text-left"
                style="width: 10%; border-right: 1px solid #eeeeee"
              >
                Date
              </th>
              <th
                class="text-left"
                style="border-right: 1px solid #eeeeee; width: 33%"
              >
                <v-row>
                  <v-col
                    cols="1"
                    v-for="month in months"
                    :key="month"
                    class="ma-0 pa-0 py-1 px-0 grey--text text--lighten-1"
                    >{{ month }}</v-col
                  >
                </v-row>
              </th>
              <th class="text-left" style="border-right: 1px solid #eeeeee">
                Badges
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="campaign in myCampaigns"
              :key="campaign.id"
              class="my-2"
              style="margin: 50px"
            >
              <td class="table-cell">
                <span
                  v-if="
                    $isBetween(
                      new Date().toISOString(),
                      campaign.start_date,
                      campaign.end_date
                    )
                  "
                >
                  <v-chip small color="#F3A968" class="white--text">
                    Active
                  </v-chip>
                </span>
                <span
                  v-else-if="
                    $isSameOrBefore(
                      new Date().toISOString(),
                      campaign.start_date
                    )
                  "
                >
                  <v-chip small color="warning" class="white--text">
                    Pending
                  </v-chip>
                </span>
                <span v-else>
                  <v-chip small color="#6FCF96" class="white--text">
                    Complete
                  </v-chip>
                </span>
              </td>
              <td class="table-cell">
                <span class="subtitle-1 text-capitalize font-weight-bold">{{
                  campaign.name
                }}</span>
              </td>
              <td class="table-cell">
                <div v-if="campaign.keywords.length > 0">
                  <span
                    v-for="keyword in campaign.keywords"
                    :key="keyword.keyword"
                  >
                    #{{ keyword.keyword }}</span
                  >
                </div>
                <div v-else>-</div>
              </td>
              <td class="table-cell">Toronto,CA</td>
              <td class="table-cell">
                <span class="subtitle-1 font-weight-bold">{{
                  campaign.template.name
                }}</span>
              </td>
              <td class="table-cell">
                {{
                  `${campaign.start_date.substr(
                    0,
                    10
                  )} - ${campaign.end_date.substr(0, 10)}`
                }}
              </td>
              <td class="table-cell-progressbar">
                <v-row>
                  <v-divider
                    vertical
                    style="height: 35px; border-left: #eeeeee 1px solid"
                  ></v-divider>
                  <campaign-progressbar
                    class="mt-2"
                    style="height: 20px"
                    :startDate="campaign.start_date"
                    :endDate="campaign.end_date"
                  />
                  <v-divider
                    vertical
                    style="height: 35px; border-left: #eeeeee 1px solid"
                  ></v-divider>
                </v-row>
              </td>
              <td class="table-cell">
                <v-row>
                  <v-avatar
                    v-for="badge in campaign.badges"
                    :key="badge.image_url"
                    size="35"
                    class="ma-2 pa-2"
                    style="border: 2px solid darkGrey"
                  >
                    <img :src="badge.image_url" alt="alt" />
                  </v-avatar>
                </v-row>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <v-container fluid :class="$root.addExtraMargin ? 'ml-14' : null">
      <v-row justify="space-between">
        <v-row>
          <v-col cols="12" md="4">
            <v-select
              @click:clear="resetFilters"
              v-model="selectedFilter"
              :items="filters"
              label="Sort"
              solo
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" md="4" v-if="selectedFilter === 'Activation Type'">
            <v-select
              v-model="selectedTag"
              :items="tags"
              label="Select Activation Type"
              solo
            ></v-select>
          </v-col>
        </v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            solo
            name="name"
            label="Search"
            id="id"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!loading && filteredMyKits.length > 0" class="mx-auto">
        <!-- <v-col
        cols="12"
        md="2"
        sm="4"
        v-for="(kit, index) in filteredMyKits"
        :key="index"
      >

      </v-col> -->
        <div
          class="ma-4 d-flex flex-auto"
          v-for="(kit, index) in filteredMyKits"
          :key="index"
        >
          <v-card max-width="300px">
            <v-card flat color="grey lighten-4">
              <v-img contain :src="kit.image_url" height="200px"></v-img>
            </v-card>
            <v-card-title class="px-2 py-1">
              <span class="subtitle-1">{{ kit.name }}</span>
            </v-card-title>
            <v-card-subtitle class="px-2 py-1">
              {{ kit.desc }}
            </v-card-subtitle>
            <v-card-actions>
              <!-- <v-btn
              class="mr-2"
              icon
              @click="$previewContest(`${contestUrl}/${kit.slug}/preview`)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn> -->
              <span v-if="!kit.is_published" style="width: 100%">
                <v-btn
                  @click="togglePublish(kit)"
                  block
                  color="primary"
                  class="white--text"
                  >Start Campaign</v-btn
                >
              </span>
              <span v-else style="width: 100%">
                <v-btn
                  @click="$router.push(`/results/${kit.id}`)"
                  block
                  color="primary"
                  class="white--text"
                  >View Campaign</v-btn
                >
              </span>
            </v-card-actions>
          </v-card>
        </div>
      </v-row>
      <v-row v-else-if="!loading" justify="center">
        <p>No campaigns found.</p>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { CampaignApiEndpoints } from '@/api/campaigns/CampaignApi'
import { $isSameOrAfter, $startOf } from '@/utils/datetime'
export default {
  mounted() {
    this.loadRequests('')
    this.getUniqueTags()
  },
  components: {
    CampaignProgressbar: () => import('@/components/UI/campaigns/CampaignProgressbar')
  },
  data: () => ({
    tags: [],
    selectedTag: '',
    filters: ['Activation Type'],
    selectedFilter: '',
    search: '',
    myCampaigns: [],
    loading: false,
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
  }),
  methods: {
    async loadRequests(filtertag) {
      this.loading = true
      let res
      try {
        res = await CampaignApiEndpoints.Campaigns.getAll({
          filter_tag: filtertag,
          badges: true,
          keywords: true,
          template: true
        }, { limit: 500 })
      } catch (error) {
        return error
      }
      const thisYear = $startOf('year')
      this.myCampaigns = res.results.map(i => { i.expand = false; return i }).sort((a, b) => {
        const d1 = new Date(a.start_date)
        const d2 = new Date(b.start_date)
        return d1 - d2
      }).filter(i => $isSameOrAfter(i.start_date, thisYear.toISOString()))
      this.loading = false
    },
    async togglePublish(kit) {
      try {
        await CampaignApiEndpoints.Campaigns.togglePublishStatus(kit.id)
      } catch (error) {
        console.log(error)
        this.$snackbar({ text: error.error.msg, y: 'top', x: 'right', color: 'errorButton', icon: 'mdi-alert', timeout: 7000 })
        return error
      }
      kit.is_published = true
    },
    async getUniqueTags() {
      const res = await CampaignApiEndpoints.Tags.getUniqueTags('activation_type')
      this.tags = res.payload
    },
    resetFilters() {
      this.selectedFilter = ''
      this.selectedTag = ''
    }
  },
  computed: {
    filteredMyKits() {
      if (this.search === '') {
        return this.myCampaigns
      }
      return this.myCampaigns.filter(i => i.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    }
  },
  watch: {
    selectedTag: {
      handler: function (x, y) {
        this.loadRequests(x)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
tr.spaceUnder > td {
  padding-bottom: 1em;
}

.my-table {
  border-top: 15px solid#E0E0E0 !important;
  border-left: 15px solid#E0E0E0 !important;
  border-right: 15px solid#E0E0E0 !important;
  border-bottom: 25px solid#E0E0E0 !important;
}

.table-cell {
  border-top: 8px solid #e0e0e0;
  padding: 10px 15px 10px 15px !important;
}

.table-cell-progressbar {
  border-top: 8px solid #e0e0e0;
  padding: 10px 15px 10px 15px !important;
  padding: 0 i !important;
}
</style>
