<template>
  <v-container fluid>
    <v-row justify="space-between">
      <v-col cols="12" md="4">
        <v-select label="Sort" solo></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="search" solo name="name" label="Search" id="id"></v-text-field>
      </v-col>
    </v-row>
    <v-container>
      <v-row v-if="searchedKits.length > 0">
      <v-col class="flex" cols="12" md="3" sm="4" v-for="(kit, index) in searchedKits" :key="index">
        <v-card height="100%">
          <v-card-title style="max-height: 100px; font-size: 17px; padding: 7px 7px 7px 5px;">
            {{ kit.name.length > 30? kit.name.substr(0,30) + '...': kit.name }}
          </v-card-title>
          <v-img class="grey lighten-3" contain :src="kit.image_url" height="165px"></v-img>
          <v-divider></v-divider>
          <v-card-subtitle class="px-2 mb-5">
            {{ kit.desc }}
          </v-card-subtitle>
          <br />

          <v-card-actions style="position: absolute; bottom: 0px; width: 100%">
            <v-btn
              @click="$router.push(`/view-kit/${kit.id}`)"
              block
              color="primary"
              >View Details</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <p>No kits found.</p>
    </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { CampaignApiEndpoints } from '@/api/campaigns/CampaignApi'
export default {
  created() {
    this.$root.$on('business-changed', (e) => {
      this.loadKits()
    })
    this.loadKits()
  },
  data: () => ({
    search: '',
    kits: []
  }),
  methods: {
    // Kits = Templates
    async loadKits() {
      this.kits = []
      let res
      try {
        res = await CampaignApiEndpoints.Templates.getAll(1, 50)
      } catch (error) {
        this.kits = []
        return error
      }
      this.kits.push(...res.results)
    }
  },
  computed: {
    searchedKits() {
      if (this.search === '') {
        return this.kits
      }
      return this.kits.filter(kit => kit.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
