/* eslint-disable prefer-const */
<template>
  <v-container>
    <v-row justify="space-between">
      <v-col cols="12" md="4">
        <v-select label="Sort" solo></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field solo name="name" label="Search" id="id"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        sm="4"
        v-for="(kit, index) in campaignRequests"
        :key="index"
      >
        <v-card height="100%">
          <v-card flat color="grey lighten-4">
            <v-img contain :src="kit.campaign.image_url" height="200px"></v-img>
          </v-card>
          <v-card-title class="px-2">
            <span class="subtitle-1">{{ kit.campaign.name }}</span>
          </v-card-title>
          <v-card-subtitle class="px-2">
            {{ kit.campaign.desc }}
          </v-card-subtitle>

          <v-card-actions>
            <v-row>
              <v-col cols="12" md="12">
                <v-btn
                  block
                  :to="`/manage/${kit.campaign.id}/request/${kit.id}/details`"
                  color="primary"
                  >Review Request</v-btn
                >
              </v-col>
              <!-- <v-col cols="2" md="3">
                <v-btn icon @click="show = !show">
                  <v-icon>{{
                    show ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-col> -->
            </v-row>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>
              <v-card-text>
                <p class="overline">
                  <v-icon left>{{mdiAccount}}</v-icon> REQUEST USER DETAILS:
                </p>
                <v-row>
                  <v-col class="py-1" cols="12" md="4"
                    ><span class="font-weight-medium body-2">
                      Name:</span
                    ></v-col
                  >
                  <v-col class="py-1" cols="12" md="8"
                    >{{ kit.requester_user_details.first_name }}
                    {{ kit.requester_user_details.last_name }}</v-col
                  >
                </v-row>
                <v-row>
                  <v-col class="py-1" cols="12" md="4"
                    ><span class="font-weight-medium body-2">
                      Email:</span
                    ></v-col
                  >
                  <v-col class="py-1" cols="12" md="8">{{
                    kit.requester_user_details.email
                  }}</v-col>
                </v-row>
                <v-row>
                  <v-col class="py-1" cols="12" md="4"
                    ><span class="font-weight-medium body-2">
                      Phone:</span
                    ></v-col
                  >
                  <v-col class="py-1" cols="12" md="8">{{
                    kit.requester_user_details.phone
                  }}</v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiAccount } from '@mdi/js'
import { CampaignApiEndpoints } from '@/api/campaigns/CampaignApi'
export default {
  mounted() {
    this.loadRequests()
  },
  data: () => ({
    mdiAccount,
    show: true,
    campaignRequests: []
  }),
  methods: {
    async loadRequests() {
      let res
      try {
        res = await CampaignApiEndpoints.Requests.getAll()
      } catch (error) {
        return error
      }
      this.campaignRequests = res.results
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
