/* eslint-disable camelcase */
import { Fetch } from '../fetch/FetchBase.js'
const API_URL = process.env.VUE_APP_CONSUMERS_API_URL + '/api/v1'
const API_TOKEN = process.env.VUE_APP_CONSUMERS_API_TOKEN
const ConsumersApiClient = new Fetch({
  baseURL: API_URL,
  headers: {
    'x-api-token': API_TOKEN
  }
})

export const ConsumersApiEndpoints = {
  Partners: {
    findManyUsers(body) {
      return ConsumersApiClient.post({
        url: '/partners/users/find/many?attributes=true&type=campaign_badge&tags=true',
        body
      })
    },
    findUserActivity(userId, businessId, page = 1, limit = 25) {
      return ConsumersApiClient.get({
        url: `/partners/logs/users/${userId}?business_id=${businessId}&page=${page}&limit=${limit}`
      })
    },
    findUser(userId, businessId) {
      return ConsumersApiClient.get({
        url: `/partners/users/${userId}?business_id=${businessId}&contact_details=true&address=false&attributes=true&locations=true`
      })
    }
  }
}
