<template>
  <v-container class="mt-10 mx-4" fluid>
    <!-- Skeletons -->
    <v-fade-transition mode="out-in" leave-absolute hide-on-leave>
      <v-row v-if="loading">
        <!-- Left Side Benefits Details -->
        <v-col cols="12" md="4">
          <v-skeleton-loader
            transition="v-fade-transition"
            max-width="300"
            type="card"
          ></v-skeleton-loader>
        </v-col>
        <!-- Icons and Images -->
        <v-col cols="12" md="8" class="mt-10">
          <!-- Icons row -->
          <v-row>
            <v-col v-for="x in 4" :key="x" cols="3">
              <v-row justify="space-around" align="center">
                <div>
                  <v-skeleton-loader
                    class="avatar"
                    transition="v-fade-transition"
                    type="avatar"
                  ></v-skeleton-loader>
                </div>
                <v-icon class="d-inline" large>mdi-chevron-right</v-icon>
              </v-row>
              <v-row>
                <v-col cols="8" offset>
                  <v-skeleton-loader
                    transition="v-fade-transition"
                    type="text"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Images Row -->
          <v-row class="mt-10">
            <v-col cols="3" class="mx-0 px-0 px-1" v-for="x in 4" :key="x">
              <v-skeleton-loader
                type="image"
                min-height="350"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-fade-transition>
    <v-fade-transition mode="out-in" leave-absolute hide-on-leave>
      <v-row v-if="!loading">
        <!-- Left Side Benefits Details -->
        <v-col cols="12" md="3">
          <v-card max-width="87%">
            <v-card-title class="px-2 font-weight-bold">
              {{ kit.name }}
            </v-card-title>
            <v-img
              src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
              height="200px"
            ></v-img>

            <v-card-subtitle class="px-2">
              {{ kit.desc }}
            </v-card-subtitle>
            <v-card-text class="px-4">
              <p
                class="
                  font-weight-bold
                  subtitle-1
                  pa-0
                  ma-a
                  mb-1
                  font-weight-bold
                "
              >
                Benefits
              </p>
              <v-divider class="mx-1 mb-2"></v-divider>
              <v-row
                v-for="item in kit.benefits"
                :key="item"
                justify="center"
                align-content="center"
              >
                <v-col class="py-0" cols="2">
                  <v-icon left color="success" class="d-inline"
                    >mdi-check</v-icon
                  >
                </v-col>
                <v-col class="py-0" cols="10">
                  <p class="ma-0 black--text">
                    {{ item }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="showModal = true" block color="primary"
                >Start Campaign</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- Icons and Images -->
        <v-col cols="12" md="9" class="mt-6 pl-11">
          <!-- Icons row -->
          <v-row>
            <v-col v-if="topDivScrollable" cols="1" align-self="center">
              <v-btn @click="scrollTopDiv('left')" icon fab color="grey"
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
            </v-col>
            <v-col :cols="topDivScrollable ? '10' : '12'">
              <div ref="top" class="d-flex flow-wrapper">
                <!-- Each Flow Item -->
                <v-col
                  cols="6"
                  md="2"
                  v-for="(flow_detail, index) in kit.flow_details"
                  :key="flow_detail.content_order"
                >
                  <v-row justify="space-around">
                    <v-avatar size="125" color="grey lighten-3">
                      <v-img contain :src="flow_detail.image_url"></v-img>
                    </v-avatar>
                    <v-icon
                      :color="
                        index < kit.flow_details.length - 1 ? 'black' : 'white'
                      "
                      >mdi-chevron-right</v-icon
                    >
                  </v-row>
                  <v-row justify="start">
                    <v-col cols="9" offset class="px-0">
                      <p class="body-2 ma-0 text-center ml-1">
                        {{ flow_detail.desc }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </v-col>
            <v-col v-if="topDivScrollable" cols="1" align-self="center">
              <v-btn @click="scrollTopDiv('right')" icon fab color="grey"
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <!-- Images Row -->
          <v-row class="mr-4" style="position: relative"
            ><v-icon
              @click="scrollBotDiv('left')"
              color="grey"
              style="position: absolute; top: 45%; left: -25px"
              >mdi-chevron-left</v-icon
            >
            <v-col cols="12">
              <div ref="bot" class="d-flex flow-wrapper">
                <!-- Each Flow Item -->
                <!-- <v-col
                  cols="6"
                  md="3"
                  v-for="x in kit.flow_previews"
                  :key="x.sort_order"
                >

                </v-col> -->
                <v-row>
                  <div v-for="x in kit.flow_previews" :key="x.sort_order">
                    <v-img
                      v-if="x.type === 'image'"
                      contain
                      :src="x.url"
                      aspect-ratio="1"
                      height="405"
                      width="200px"
                      class="grey lighten-2 mx-1"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <video
                      class="video"
                      v-else-if="x.type === 'video'"
                      height="355px"
                      width="200px"
                      controls
                    >
                      <source :src="x.url" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </v-row>
              </div>
            </v-col>
            <v-icon
              @click="scrollBotDiv('right')"
              color="grey"
              style="position: absolute; top: 45%; right: 25px"
              >mdi-chevron-right</v-icon
            >
          </v-row>
        </v-col>
      </v-row>
    </v-fade-transition>
    <v-dialog
      fullscreen
      persistent
      v-model="showModal"
      max-width="750px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title> Basic Details </v-card-title>
        <v-card-subtitle primary-title>
          Please provide some basic details that will allow us to initiate your
          kit request.
        </v-card-subtitle>
        <v-card-text style="position: relative">
          <forms-engine-component
            :api-url="`${formsApi}`"
            ref="form"
            v-if="showModal"
            :client-token="form.client_token"
            :form-id="form.sections[0].form_id"
            :submission-token="form.submission_token"
            :auth-token="vg_user.token"
            :rendering-type="'section'"
            :sections="form.sections"
          ></forms-engine-component>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showModal = false" color="grey" text>Cancel</v-btn>
          <v-btn
            :disabled="formSubmitButtonClicked"
            :loading="formSubmitButtonClicked"
            @click="submitForm"
            color="primary"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { CampaignApiEndpoints } from '@/api/campaigns/CampaignApi'
import { mapGetters } from 'vuex'
export default {
  async mounted() {
    await this.loadKitDetails()
    this.getFormSubmissionDetails()
  },
  data: () => ({
    formsApi: process.env.VUE_APP_FORMS_API_URL,
    items: [10, 9, 8],
    formSubmitButtonClicked: false,
    everythingloaded: false,
    topDivScrollable: false,
    bottomDivScrollable: false,
    showModal: false,
    kit: '',
    loading: false,
    form: ''
  }),
  methods: {
    async loadKitDetails() {
      this.loading = true
      let response
      try {
        response = await CampaignApiEndpoints.Templates.getById(this.$route.params.id)
      } catch (error) {
        this.loading = false
        return error
      }
      this.kit = response
      this.loading = false
      await this.$nextTick()
      const topScrollable = this.$refs.top.scrollHeight > this.$refs.top.clientHeight || this.$refs.top.scrollWidth > this.$refs.top.clientWidth
      const botScrollable = this.$refs.bot.scrollHeight > this.$refs.bot.clientHeight || this.$refs.bot.scrollWidth > this.$refs.bot.clientWidth
      this.topDivScrollable = topScrollable
      this.bottomDivScrollable = botScrollable
    },
    async getFormSubmissionDetails() {
      let response
      try {
        response = await CampaignApiEndpoints.Requests.getFormSubmission(this.$route.params.id)
      } catch (error) {
        return error
      }
      this.form = response
    },
    async submitForm() {
      const valid = await this.$refs.form._validate()
      if (valid) {
        this.formSubmitButtonClicked = true
        try {
          await this.$refs.form._submitForm()
        } catch (error) {
          return error
        }
        this.formSubmitButtonClicked = false
        this.showDialog = false
        this.$router.push('/')
        this.showSuccessAlert({
          text: 'Your campaign request was submitted successful, your account manager will contact you within 48hrs.',
          title: 'Woo Hoo!'
        })
      }
    },
    scrollTopDiv(dir) {
      this.sideScroll(this.$refs.top, dir, 5, 100, 5)
    },
    scrollBotDiv(dir) {
      this.sideScroll(this.$refs.bot, dir, 5, 100, 5)
    },
    sideScroll(element, direction, speed, distance, step) {
      let scrollAmount = 0
      var slideTimer = setInterval(function () {
        if (direction === 'left') {
          element.scrollLeft -= step
        } else {
          element.scrollLeft += step
        }
        scrollAmount += step
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer)
        }
      }, speed)
    }
  },
  computed: {
    ...mapGetters({
      vg_user: 'auth/user'
    })
  }
}
</script>

<style lang="scss" scoped>
.avatar .v-skeleton-loader__avatar .v-skeleton-loader__bone {
  height: 100px !important;
  width: 100px !important;
}

video {
  max-width: 200px;
  max-height: 405px;
  min-height: 405px;
  background: #e0e0e0;
}

.flow-wrapper {
  display: flex;
  overflow-x: auto;
  overflow-y: auto;
  flex-wrap: nowrap;
}
</style>
