<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-card class="mr-9" flat outlined>
          <!-- <v-card-title class="px-2">  2 </v-card-title> -->
          <v-row class="mt-5">
            <v-col cols="12">
              <v-row justify="center">
                <v-avatar size="125">
                  <v-icon size="75">fas fa-user</v-icon>
                </v-avatar></v-row
              >
            </v-col>
            <v-col cols="justify-center" class="py-0">
              <v-row justify="center">
                <p class="px-2 title py-0 my-0">{{ userName }}</p>
              </v-row>
              <v-row justify="center">
                <p class="px-2 subtitle-2 font-weight-regular my-0">
                  Member Since:
                  {{ user.created_at.substr(0, 10).replaceAll("-", "/") }}
                </p>
              </v-row>
              <v-row justify="center">
                <p class="px-2 subtitle-2 font-weight-regular my-0">
                  Last Engaged:
                  {{
                    user.attributes.length > 0
                      ? user.attributes[0].created_at
                          .substr(0, 10)
                          .replaceAll("-", "/")
                      : "-"
                  }}
                </p>
              </v-row>
              <!-- <p></p> -->
            </v-col>
          </v-row>
          <br />
          <v-divider class="mx-1 mb-2"></v-divider>
          <v-card-text class="mb-0 pb-0 mb-4">
            <v-row class="px-4" align="center">
              <v-icon left size="35">mdi-facebook</v-icon>
              3.1k Followers
            </v-row>
            <v-row class="px-4" align="center">
              <v-icon left size="35">mdi-twitter</v-icon>
              3.1k Followers
            </v-row>
            <v-row class="px-4" align="center">
              <v-icon left size="35">mdi-instagram</v-icon>
              3.1k Followers
            </v-row>
            <v-row class="px-4" align="center">
              <v-icon left size="35">mdi-gmail</v-icon>
              3.1k Followers
            </v-row>
          </v-card-text>
          <v-divider class="mx-1 mb-2"></v-divider>
          <v-card-text class="mb-5 pb-0 pt-0">
            <p class="overline mb-0">Badges</p>
            <v-row class="px-4 mb-0" align="center">
              <v-avatar v-for="(i,index) in activity.slice(0,3)" :key="index+'avatar'" size="40" class="mx-1 pa-2">
                <img :src="getActivityImage(i)" alt="alt" />
              </v-avatar>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="9">
        <!-- Render graph -->
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="10" class="px-0">
              <apexchart
                height="250px"
                type="area"
                :options="getChartData.options"
                :series="getChartData.series"
              ></apexchart>
            </v-col>
            <v-col cols="12" md="2">
              <v-card min-width="145px" outlined class="mb-3">
                <v-card-text class="py-0 py-2 px-0">
                  <v-row class="px-1 mr-5" justify="end" align="center">
                    <v-col class="ma-0 pa-0 ml-3" cols="4">
                      <v-icon left size="35">mdi-facebook</v-icon>
                    </v-col>
                    <v-col class="ma-0 pa-0">
                      <v-row class="pl-3" align="center">
                        <p class="text-h4 mb-0 black--text">2</p>
                        <span style="line-height: 15px" class="ml-2"
                          >Energy & <br />
                          Drink</span
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card  min-width="145px" outlined class="mb-3">
                <v-card-text class="py-0 py-2 px-0">
                  <v-row class="px-1 mr-5" justify="end" align="center">
                    <v-col class="ma-0 pa-0 ml-3" cols="4">
                      <v-icon left size="35">mdi-facebook</v-icon>
                    </v-col>
                    <v-col class="ma-0 pa-0">
                      <v-row class="pl-3" align="center">
                        <p class="text-h4 mb-0 black--text">2</p>
                        <span style="line-height: 15px" class="ml-2"
                          >Health & <br />
                          Beyond</span
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!-- Users List -->
        <v-col cols="12">
          <v-container fluid class="grey lighten-3">
            <v-row justify="center">
              <v-pagination
                @next="nextPage"
                @previous="previousPage"
                @input="goTo"
                v-model="page"
                :length="totalPages"
              ></v-pagination>
            </v-row>
            <v-row v-for="i in activity" :key="i.id">
              <v-col cols="1">
                <v-row justify="center" align="center">
                  <v-avatar
                    v-if="getActivityImage(i)"
                    size="40"
                    class="mx-1 pa-2"
                  >
                    <img :src="getActivityImage(i)" alt="alt" />
                  </v-avatar>
                  <!-- <v-icon size="65" class="mt-2">mdi-facebook</v-icon> -->
                </v-row>
              </v-col>
              <v-col>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="4">{{
                        i.created_at.substr(0, 10).replaceAll("-", "/")
                      }}</v-col>
                      <v-col cols="12" md="8"
                        >{{ userName }} a badge after entering
                        {{ i.activity_details.campaign_name }}</v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ConsumersApiEndpoints } from '@/api/consumers/ConsumersApi'
import { mapGetters } from 'vuex'
import { $format } from '@/utils/datetime'
// import { CampaignApiEndpoints } from '@/api/campaigns/CampaignApi'

export default {
  mounted() {
    this.getUserActivities(1)
    this.findUser()
  },
  data: function () {
    return {
      user: {},
      activity: [],
      page: 1,
      totalPages: 10
    }
  },
  methods: {
    async findUser() {
      const res = await ConsumersApiEndpoints.Partners.findUser(this.$route.params.userid, this.vg_activeBusiness.id)
      console.log(res)
      this.user = res
    },
    async getUserActivities(page) {
      const res = await ConsumersApiEndpoints.Partners.findUserActivity(this.$route.params.userid, this.vg_activeBusiness.id, page)
      console.log(res)
      this.activity = res.results
      this.totalPages = res.total_pages
      this.page = res.current_page
    },
    goTo(p) {
      this.getUserActivities(p)
    },
    nextPage(p) {
      this.goTo(p)
    },
    previousPage(p) {
      this.goTo(p)
    },
    getActivityImage(activity) {
      const badge = this.user.attributes.find(i => i.details.campaign_id === activity.activity_details.campaign_id)
      if (badge) {
        return badge.details.badge_image_url
      }
      return false
    }
  },
  computed: {
    ...mapGetters({
      vg_activeBusiness: 'auth/activeBusiness'
    }),
    getChartData() {
      const chartData = {
        series: [{
          name: 'User Activity',
          data: []
        }],
        options: {
          colors: ['#8c8c8c'],
          chart: {
            id: 'User Activity Chart'
          },
          xaxis: {
            categories: []
          }
        }
      }
      if (this.activity.length < 1) return chartData
      let data = {}
      this.activity.forEach(a => {
        const month = $format(a.created_at, 'MMM')
        if (data[month]) {
          data[month] = data[month] + 1
        } else {
          data[month] = 1
        }
      })
      // If its only one month, then separate out days
      if (Object.keys(data).length === 1) {
        // Reset data
        data = {}
        this.activity.forEach(a => {
          const date = $format(a.created_at, 'DD MMM, YY')
          if (data[date]) {
            data[date] = data[date] + 1
          } else {
            data[date] = 1
          }
        })
        //
      }
      chartData.series[0].data = Object.values(data).reverse()
      chartData.options.xaxis.categories = Object.keys(data).reverse()
      return chartData
    },
    userName() {
      return this.user.first_name ? this.user.first_name + ' ' + this.user.last_name : this.user.username
    }
  }
}
</script>

<style lang="scss" scoped>
.my-table {
  border-collapse: separate;
  border-spacing: 0 1em;
}
</style>
