<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-card class="mr-9" flat outlined>
          <v-card-title class="px-2">{{ campaign.name }}</v-card-title>
          <v-img
            class="grey lighten-3"
            contain
            :src="campaign.image_url"
            height="200px"
          ></v-img>

          <v-card-subtitle class="px-2">
            {{ campaign.desc }}
          </v-card-subtitle>
          <v-divider class="mx-1 mb-2"></v-divider>
          <div>
            <p class="body-2 mx-2">
              {{ campaign.start_date.substr(0, 10).replaceAll("-", "/") }} -
              {{ campaign.end_date.substr(0, 10).replaceAll("-", "/") }}
            </p>
            <p
              class="body-2 mx-2 my-0"
              v-for="keyword in campaign.keywords"
              :key="keyword.id"
            >
              #{{ keyword.keyword }}
            </p>
          </div>
          <v-divider class="mx-1 mb-2"></v-divider>
          <v-card-text class="mb-0 pb-0">
            <v-row
              class="py-1"
              v-for="item in campaign.benefits"
              :key="item"
              align-content="center"
            >
              <v-col
                class="py-0 d-flex align-content-center align-center justify-center"
                cols="3"
              >
                <v-icon left color="success" class="d-inline">mdi-check</v-icon>
              </v-col>
              <v-col class="py-0" cols="9">
                <p class="ma-0 black--text">
                  {{ item.benefit }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <div>
            <p class="overline mx-2 my-1">Badges</p>
            <v-avatar
              v-for="i in campaign.badges"
              :key="i.image_url"
              size="40"
              class="mx-1 pa-2"
            >
              <img :src="i.image_url" alt="alt" />
            </v-avatar>
          </div>
          <div>
            <p class="overline mx-2 my-1">Created By:</p>
            <v-row class="mx-1 pb-3" align="center"
              ><v-avatar size="35" class="mx-1 pa-2">
                <v-icon>fas fa-user-circle</v-icon>
              </v-avatar>
              <p class="subtitle-2 font-weight-regular mb-0 ml-3">
                {{ campaign.created_by.first_name }}
                {{ campaign.created_by.last_name }}
              </p>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="9">
        <!-- Render graph -->
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="9" class="px-0">
              <apexchart
                height="250px"
                type="area"
                :options="getChartData.options"
                :series="getChartData.series"
              ></apexchart>
            </v-col>
            <v-col cols="12" md="3">
              <v-card outlined class="mb-3">
                <v-card-text class="py-0 py-2">
                  <v-row class="px-1 mr-5" justify="end" align="center">
                    <p class="text-h4 mb-0 black--text">
                      {{
                        campaignData.total_entries
                          ? campaignData.total_entries
                          : "0"
                      }}
                    </p>
                    <span style="line-height: 15px" class="ml-2"
                      >Total <br />
                      Engagements</span
                    >
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card outlined class="mb-3">
                <v-card-text class="py-0 py-2">
                  <v-row class="px-1 mr-5" justify="end" align="center">
                    <p class="text-h4 mb-0 black--text">
                      {{
                        campaignData.unique_entries
                          ? campaignData.unique_entries
                          : "0"
                      }}
                    </p>
                    <span style="line-height: 15px" class="ml-2"
                      >Unique <br />
                      Engagements</span
                    >
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card outlined class="mb-3">
                <v-card-text class="py-0 py-2">
                  <v-row class="px-1 mr-5" justify="end" align="center">
                    <p class="text-h4 mb-0 black--text">
                      {{
                        campaignData.average_per_consumer
                          ? campaignData.average_per_consumer
                          : "0"
                      }}
                    </p>
                    <span style="line-height: 15px" class="ml-2"
                      >Average <br />
                      Engagements <br />
                      Per Customer</span
                    >
                  </v-row>
                </v-card-text>
              </v-card>
              <v-btn color="grey darken-2 white--text" small block
                >View Full Report</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <!-- Users List -->
        <v-col cols="12">
          <v-container fluid class="grey lighten-2">
            <v-simple-table height="40vh" fixed-header class="my-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Customer</th>
                    <th class="text-left">Last Active</th>
                    <th class="text-left">Location</th>
                    <th class="text-left" style="width: 35%">
                      Badges Earned In Past 30 Days
                    </th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody v-if="users.length > 0">
                  <tr class="table-row" v-for="user in users" :key="user.id">
                    <td class="table-cell">
                      <span v-if="user.first_name">
                        {{ user.first_name }} {{ user.last_name }}
                      </span>
                      <span v-else>
                        {{ user.username }}
                      </span>
                    </td>
                    <td class="table-cell">
                      {{ user.updated_at.substr(0, 10) }}
                    </td>
                    <td class="table-cell">
                      {{ user.location ? user.location : "N/A" }}
                    </td>
                    <td class="table-cell badges-cell">
                      <div style="max-height: 100px; overflow: auto">
                        <v-avatar
                          v-for="i in user.attributes"
                          :key="i.details.badge_image_url"
                          size="40"
                          class="mx-1 pa-2 grey lighten-3"
                        >
                          <img :src="i.details.badge_image_url" alt="alt" />
                        </v-avatar>
                      </div>
                    </td>
                    <td
                      class="table-cell"
                      @click="$router.push(`/user/${user.id}`)"
                    >
                      <v-btn small outlined color="grey">View Profile</v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="table-cell" colspan="5">
                      <div class="text-center">No users data found.</div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CampaignApiEndpoints } from '@/api/campaigns/CampaignApi'
import { ConsumersApiEndpoints } from '@/api/consumers/ConsumersApi'
import { mapGetters } from 'vuex'
// import { CampaignApiEndpoints } from '@/api/campaigns/CampaignApi'

export default {
  mounted() {
    this.getResults()
    this.getCampaign()
  },
  data: function () {
    return {
      users: [],
      campaignData: {},
      campaign: {},
      options: {
        colors: ['#8c8c8c'],
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
        }
      },
      series: [{
        name: 'series-1',
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }]
    }
  },
  methods: {
    async getResults() {
      const res = await CampaignApiEndpoints.Analytics.getCampaignSummary(this.$route.params.id)

      this.campaignData = res
      const data = {
        business_id: this.vg_activeBusiness.id,
        user_ids: res.consumer_ids
      }
      const users = await ConsumersApiEndpoints.Partners.findManyUsers(data)
      this.users = users.payload
    },
    async getCampaign() {
      const res = await CampaignApiEndpoints.Campaigns.getById(this.$route.params.id, { benefits: true, keywords: true, badges: true, creator: true })
      this.campaign = res
    }
  },
  computed: {
    ...mapGetters({
      vg_activeBusiness: 'auth/activeBusiness'
    }),
    getChartData() {
      if (Object.keys(this.campaignData).length > 0) {
        return {
          options: {
            colors: ['#0db659'],
            xaxis: {
              categories: this.campaignData.entries_by_day.map(i => i.date)
            }
          },
          series: [{
            name: 'Average Entries',
            data: this.campaignData.entries_by_day.map(i => i.entries)
          }]
        }
      }
      return {
        options: {
          colors: ['#0db659'],
          xaxis: {
            categories: ['No engagements yet']
          }
        },
        series: [{
          name: 'Average Entries',
          data: [0]
        }]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my-table {
  border-top: 15px solid#E0E0E0 !important;
  border-left: 15px solid#E0E0E0 !important;
  border-right: 15px solid#E0E0E0 !important;
  border-bottom: 50px solid#E0E0E0 !important;
}

.table-cell {
  border-top: 13px solid #e0e0e0;
  padding: 10px !important;
}
</style>
