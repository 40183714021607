// async function handleResponse(url, options) {
//   try {
//     const result = await fetch(url, options)
//     if (!result.ok) {
//       const error = await result.json()
//       result.error = error
//       throw result
//     }
//     return await result.json()
//   } catch (error) {
//     throw error
//   }
// }
async function handleResponse(url, options) {
  const result = await fetch(url, options)
  if (!result.ok) {
    const error = await result.json()
    result.error = error
    throw result
  }
  return await result.json()
}

async function handleDataCalls(method, { url, headers = undefined, body = undefined }) {
  const options = {
    method: method
  }
  if (body) {
    options.body = JSON.stringify(body)
  }
  if (headers) {
    options.headers = { ...options.headers, ...headers }
  }
  return handleResponse(url, options)
}

export class Fetch {
  constructor({ baseURL, headers = undefined }) {
    console.log('@constructor', baseURL)
    this.baseURL = baseURL
    this.headers = {
      'Content-Type': 'application/json'
    }
    if (headers) {
      this.headers = { ...this.headers, ...headers }
    }
    console.log('endConstructor', headers)
  }

  async get({ url, headers }) {
    const options = {
      method: 'GET',
      headers: { ...this.headers, ...headers }
    }
    return handleResponse(this.baseURL + url, options)
  }

  async post({ url, headers = undefined, body = undefined }) {
    let _headers = { ...this.headers }
    if (headers) {
      _headers = { ..._headers, ...headers }
    }
    return handleDataCalls('POST', { url: this.baseURL + url, headers: _headers, body })
  }

  async put({ url, headers, body }) {
    let _headers = { ...this.headers }
    if (headers) {
      _headers = { ..._headers, ...headers }
    }
    return handleDataCalls('PUT', { url: this.baseURL + url, headers: _headers, body })
  }

  async patch({ url, headers, body }) {
    let _headers = { ...this.headers }
    if (headers) {
      _headers = { ..._headers, ...headers }
    }
    return handleDataCalls('PATCH', { url: this.baseURL + url, headers: _headers, body })
  }

  async delete({ url, headers }) {
    const options = {
      method: 'delete',
      headers: { ...this.headers, ...headers }
    }
    return handleResponse(this.baseURL + url, options)
  }

  print() {
    console.log(this)
  }
}
