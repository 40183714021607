<template>
  <v-container class="grey lighten-3" fluid fill-height>
    <v-row justify="center" class="mt-15">
      <v-col cols="12" md="4">
        <v-card class="elevation-0" outlined>
          <v-card-title v-if="tab === 0">Login</v-card-title>
          <v-card-title v-else-if="tab === 1">Forgot Password</v-card-title>
          <v-card-subtitle v-if="tab === 1" class="text-left"
            >Instructions to reset your password will be sent to your
            email</v-card-subtitle
          >
          <v-card-subtitle v-if="tab === 0" class="text-left"
            >Please enter your email and password to continue</v-card-subtitle
          >
          <!-- <offset-heading v-if="tab===0" titleClass="text-sm-center font-weight-bold" title="LOGIN"></offset-heading> -->
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card-text class="px-8 py-0">
                <v-form ref="loginForm" lazy-validation>
                  <v-text-field
                    v-model="user.email"
                    :rules="emailRules"
                    prepend-icon="fas fa-user-circle"
                    label="Email"
                    required
                    aria-autocomplete="current-password"
                    validate-on-blur
                    @keyup.enter="login($event)"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    v-model="user.password"
                    :rules="passwordRules"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                    id="password"
                    autocomplete="on"
                    validate-on-blur
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    @keyup.enter="login($event)"
                  ></v-text-field>
                  <v-layout row wrap class="pt-3 pb-5">
                    <v-btn
                      block
                      :loading="buttonSpinner"
                      submit
                      color="primary"
                      @click="login($event)"
                      >Login</v-btn
                    >
                  </v-layout>
                </v-form>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="px-8 py-0">
                <v-form
                  @submit.prevent
                  ref="forgotPasswordForm"
                  lazy-validation
                >
                  <v-text-field
                    v-if="showPassResetMessage === null"
                    v-model="user.email"
                    :rules="emailRules"
                    prepend-icon="fas fa-user-circle"
                    label="Email"
                    required
                    aria-autocomplete="current-password"
                    validate-on-blur
                    type="text"
                  ></v-text-field>
                  <div v-else>
                    <p>{{ showPassResetMessage }}</p>
                  </div>
                  <v-layout row wrap class="py-4">
                    <v-btn
                      @click="
                        tab = 0;
                        showPassResetMessage = null;
                      "
                      link
                      text
                      color="primary"
                      class="text-capitalize"
                    >
                      <v-icon small left>fas fa-arrow-left</v-icon>Back to login
                    </v-btn>
                    <v-spacer v-if="showPassResetMessage === null"></v-spacer>
                    <v-btn
                      v-if="showPassResetMessage === null"
                      color="primary"
                      d-inline-flex
                      >Proceed</v-btn
                    >
                  </v-layout>
                </v-form>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-img
          max-height="100"
          contain
          src="../../assets/images/powered.png"
        ></v-img>
      </v-col>
    </v-row>
    <v-dialog
      persistent
      v-if="businessModal"
      v-model="businessModal"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card height="100vh">
        <unoapp-accounts-modal
          @unoappBusinessSelected="setActiveBusiness($event.detail)"
          :auth-token="vg_user.token"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { AuthApiEndpoints } from '@/api/auth/AuthApi'
import { mapActions, mapGetters, mapMutations } from 'vuex'
const strongPasswordRegex = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
export default {
  async mounted() {
    if (this.$route.query.token) {
      let res
      try {
        res = await AuthApiEndpoints.loginWithToken(this.$route.query.token)
      } catch (error) {
        console.log(error)
        return
      }
      const user = res.payload.user
      const business = res.payload.business
      user.token = this.$route.query.token
      this.vm_setUser(user)
      this.buttonSpinner = false
      this.vm_setActiveBusiness(business)
      // this.$vuetify.theme.themes.light.primary = this.vg_activeBusinessBrandingAndLogo.primary
      // this.$vuetify.theme.themes.light.secondary = this.vg_activeBusinessBrandingAndLogo.secondary
      // this.$vuetify.theme.themes.light.accent = this.vg_activeBusinessBrandingAndLogo.accent
      this.$router.push('/')
      // await this.registerForPushNotifications()
      this.businessModal = false
    }
    this.spinner(false)
  },
  data() {
    return {
      tab: 0,
      showPassword: false,
      user: {
        email: '',
        password: ''
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^.+@.+\..+$/.test(v) || 'E-mail must be valid'
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => strongPasswordRegex.test(v) || 'Password must be 8 characters long, contain 1 upper , 1 lower and 1 special character'
      ],
      passwordCheckRules: [v => v === this.resetPassword.password || 'Passwords do not match'],
      businessModal: false,
      resetPassword: {
        password: '',
        confirm_password: ''
      },
      showResetPassword: false,
      showResetConfirmPassword: false,
      showPassResetMessage: null,
      twoFactor: {
        status: false,
        value: '',
        token: null
      },
      buttonSpinner: false
    }
  },
  computed: {
    ...mapGetters({
      vg_user: 'auth/user',
      vg_businessesList: 'auth/businessesList',
      vg_activeBusinessBrandingAndLogo: 'auth/activeBusinessBrandingAndLogo'
    })
  },
  methods: {
    ...mapMutations({
      vm_setUser: 'auth/SET_USER',
      vm_setActiveBusiness: 'auth/SET_ACTIVE_BUSINESS'
    }),
    ...mapActions({
      va_loginWithToken: 'auth/loginWithToken',
      va_clearAuthState: 'auth/clearState'
    }),
    async login() {
      if (!this.$refs.loginForm.validate()) return
      this.buttonSpinner = true
      let res
      try {
        res = await AuthApiEndpoints.login(this.user)
      } catch (error) {
        console.log(error)
        this.buttonSpinner = false
        this.$snackbar({ text: 'Invaid login credentials.', y: 'top', x: 'right', color: 'errorButton', icon: 'mdi-account', timeout: 3500 })
        return
      }
      const user = res.payload.user
      user.token = res.payload['auth-token']
      this.vm_setUser(user)
      this.buttonSpinner = false
      this.businessModal = true
    },
    async setActiveBusiness(business) {
      this.vm_setActiveBusiness(business)
      // this.$vuetify.theme.themes.light.primary = this.vg_activeBusinessBrandingAndLogo.primary
      // this.$vuetify.theme.themes.light.secondary = this.vg_activeBusinessBrandingAndLogo.secondary
      // this.$vuetify.theme.themes.light.accent = this.vg_activeBusinessBrandingAndLogo.accent
      this.$router.push('/')
      // await this.registerForPushNotifications()
      this.businessModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.div {
  color: rgb(124, 124, 124);
}
</style>
