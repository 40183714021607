/* eslint-disable camelcase */
import { Fetch } from '../fetch/FetchBase.js'
const authToken = () => JSON.parse(localStorage.getItem('vuex')).auth.user.token
const API_URL = process.env.VUE_APP_CAMPAIGN_API_URL + '/api/v1'
const CampaignApiClient = new Fetch({ baseURL: API_URL })

export const CampaignApiEndpoints = {
  // Campaign Request Calls
  Requests: {
    getAll(campaign = true) {
      return CampaignApiClient.get({
        url: `/campaigns/requests?campaign=${campaign}`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    getById(requestId, checklist = true) {
      return CampaignApiClient.get({
        url: `/campaigns/requests/${requestId}?checklist=${checklist}`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    markCompleted(requestId) {
      return CampaignApiClient.patch({
        url: `/campaigns/requests/${requestId}`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    getFormSubmission(templateId) {
      return CampaignApiClient.get({
        url: `/campaigns/requests/forms/templates/${templateId}`,
        headers: {
          'auth-token': authToken()
        }
      })
    }
  },

  // Badges calls
  Badges: {
    getByCampaign(campaignId) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}/badges`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    addToCampaign(badgeId, campaignId) {
      return CampaignApiClient.post({
        url: `/campaigns/${campaignId}/badges/${badgeId}`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    getAll() {
      return CampaignApiClient.get({
        url: '/badges'
      })
    },
    create(data) {
      return CampaignApiClient.post({
        url: '/badges',
        body: data,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    delete(campaignId, badgeId) {
      return CampaignApiClient.delete({
        url: `/campaigns/${campaignId}/badges/${badgeId}`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    updateById(badgeId, body) {
      return CampaignApiClient.patch({
        url: `/badges/${badgeId}`,
        headers: {
          'auth-token': authToken()
        },
        body
      })
    }
  },

  // Campaign Calls
  Campaigns: {
    getChecklist(campaignId) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}/checklist`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    getById(campaignId,
      {
        settings = false,
        responses = false,
        terms = false,
        branding = false,
        flow = false,
        flow_details = false,
        badges = false,
        benefits = false,
        creator = false,
        tags = false,
        keywords = false
      }) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}?settings=${settings}&responses=${responses}&terms=${terms}&branding=${branding}&flow=${flow}&flow_details=${flow_details}&badges=${badges}&benefits=${benefits}&creator=${creator}&tags=${tags}&keywords=${keywords}`,
        headers: {
          'auth-token': authToken()
        }
      })
    },

    getAll({
      keywords = false,
      settings = false,
      responses = false,
      terms = false,
      branding = false,
      template = false,
      flow = false,
      flow_details = false,
      badges = false,
      benefits = false,
      creator = false,
      tags = false,
      checklist = false,
      filter_tag = ''
    }, { page = 1, limit = 20 }) {
      return CampaignApiClient.get({
        url: `/campaigns?page=${page}&limit=${limit}&settings=${settings}&responses=${responses}&terms=${terms}&branding=${branding}&flow=${flow}&flow_details=${flow_details}&badges=${badges}&benefits=${benefits}&creator=${creator}&tags=${tags}&checklist=${checklist}&template=${template}&keywords=${keywords}&filter_tag=${filter_tag}`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    getPublishedChecklist(campaignId) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}/checklist`
      })
    },
    togglePublishStatus(campaignId) {
      return CampaignApiClient.patch({
        url: `/campaigns/${campaignId}/publish`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    updateCampaignById(campaignId, data) {
      return CampaignApiClient.patch({
        url: `/campaigns/${campaignId}`,
        headers: {
          'auth-token': authToken()
        },
        body: data
      })
    }
  },
  Tags: {
    getUniqueTags(type) {
      return CampaignApiClient.get({
        url: `/tags/type/${type}/unique`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    getAllForCampaign(campaignId) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}/tags`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    create(campaignId, data) {
      return CampaignApiClient.post({
        url: `/campaigns/${campaignId}/tags`,
        headers: {
          'auth-token': authToken()
        },
        body: data
      })
    },
    delete(campaignId, tagId) {
      return CampaignApiClient.delete({
        url: `/campaigns/${campaignId}/tags/${tagId}`,
        headers: {
          'auth-token': authToken()
        }
      })
    }
  },
  Benefits: {
    getAllForCampaign(campaignId) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}/benefits`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    replaceAll(campaignId, data) {
      return CampaignApiClient.patch({
        url: `/campaigns/${campaignId}/benefits/replace`,
        headers: {
          'auth-token': authToken()
        },
        body: data
      })
    }
  },
  Settings: {
    get(campaignId, allNumbers = true) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}/settings?all-numbers=${allNumbers}`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    update(campaignId, settingsId, data) {
      return CampaignApiClient.patch({
        url: `/campaigns/${campaignId}/settings/${settingsId}`,
        headers: {
          'auth-token': authToken()
        },
        body: data
      })
    }
  },
  Responses: {
    get(campaignId) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}/responses`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    updateResponse(campaignId, body) {
      return CampaignApiClient.patch({
        url: `/campaigns/${campaignId}/responses`,
        headers: {
          'auth-token': authToken()
        },
        body
      })
    },
    createResponses(campaignId, body) {
      return CampaignApiClient.post({
        url: `/campaigns/${campaignId}/responses`,
        headers: {
          'auth-token': authToken()
        },
        body
      })
    }
  },
  Templates: {
    getAll(page = 1, limit = 10) {
      return CampaignApiClient.get({
        url: `/templates?page=${page}&limit=${limit}`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    getById(templateId) {
      return CampaignApiClient.get({
        url: `/templates/${templateId}`,
        headers: {
          'auth-token': authToken()
        }
      })
    }
  },
  Terms: {
    getAllTermsForCampaign(campaignId) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}/terms`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    updateCampaignTerm(campaignId, termId, data) {
      return CampaignApiClient.patch({
        url: `/campaigns/${campaignId}/terms/${termId}`,
        headers: {
          'auth-token': authToken()
        },
        body: data
      })
    },
    createTerm(campaignId, data) {
      return CampaignApiClient.post({
        url: `/campaigns/${campaignId}/terms/`,
        headers: {
          'auth-token': authToken()
        },
        body: data
      })
    },
    deleteTerm(campaignId, termId) {
      return CampaignApiClient.delete({
        url: `/campaigns/${campaignId}/terms/${termId}`,
        headers: {
          'auth-token': authToken()
        }
      })
    }
  },
  Flows: {
    getFlow(campaignId, flowDetails = true) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}/flows?flow_details=${flowDetails}`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    addFlowDetails(campaignId, flowId, data) {
      return CampaignApiClient.post({
        url: `/campaigns/${campaignId}/flows/${flowId}/details`,
        headers: {
          'auth-token': authToken()
        },
        body: data
      })
    },
    deleteFlowDetail(campaignId, flowId, flowDetailId) {
      return CampaignApiClient.delete({
        url: `/campaigns/${campaignId}/flows/${flowId}/details/${flowDetailId}`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    getFlowDetails(campaignId, flowId) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}/flows/${flowId}/details`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    updateFlowOrder(campaignId, flowId, data) {
      return CampaignApiClient.patch({
        url: `/campaigns/${campaignId}/flows/${flowId}/details`,
        headers: {
          'auth-token': authToken()
        },
        body: data
      })
    }
  },
  Prizes: {
    getPrizes(campaignId) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}/prizes`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    addPrizes(campaignId, body, refresh = false) {
      return CampaignApiClient.post({
        url: `/campaigns/${campaignId}/prizes?refresh=${refresh}`,
        headers: {
          'auth-token': authToken()
        },
        body
      })
    }
  },
  Branding: {
    getCampaignBranding(campaignId) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}/branding`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    updateBranding(campaignId, body) {
      return CampaignApiClient.patch({
        url: `/campaigns/${campaignId}/branding`,
        headers: {
          'auth-token': authToken()
        },
        body
      })
    }
  },
  Keyword: {
    getAll(campaignId) {
      return CampaignApiClient.get({
        url: `/campaigns/${campaignId}/keywords`,
        headers: {
          'auth-token': authToken()
        }
      })
    },
    createKeyword(campaignId, body) {
      return CampaignApiClient.post({
        url: `/campaigns/${campaignId}/keywords`,
        headers: {
          'auth-token': authToken()
        },
        body
      })
    },
    deleteKeyword(campaignId, keywordId) {
      return CampaignApiClient.delete({
        url: `/campaigns/${campaignId}/keywords/${keywordId}`,
        headers: {
          'auth-token': authToken()
        }
      })
    }
  },
  Analytics: {
    getCampaignSummary(campaignId) {
      return CampaignApiClient.get({
        url: `/analytics/campaigns/${campaignId}/summary `,
        headers: {
          'auth-token': authToken()
        }
      })
    }
  }
}
